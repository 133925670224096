import _ from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  ServiceRequestAPI,
  SettlementAPI,
  LintasGeneralAPI,
  ExecutionAPI
} from '../../api'
import {
  MasterModal,
  Alerts,
  ModalReject,
  ModalApprove,
  ModalEvidence,
  InputSelect,
  Button,
  ModalUnfinalized
} from '../../components'
import SrModalContent from './settlement.modal.sr.container'
import DocModalContent from './settlement.modal.doc.container'
// import ProdModalContent from './settlement.modal.prod.container'

import DelayModalContent from './delay'
import ActivityModalContent from './activity'
import CargoEquipmentModalContent from './cargo-labour-equipment'
import {
  Nav,
  Title,
  Header,
  MenuList,
  TdStyled,
  SubHeader,
  WetSignRow,
  MenuWrapper,
  ContentList,
  ImageHeader,
  WrapperData,
  VesselSchedule,
  BackgroundSign,
  InputSelectText,
  InputSelectWrapper,
  InputSelectSignWrapper,
  InputSelectWrapperInner,
  Table,
  Tbody,
  TbodyCell,
  TbodyRow,
  Thead,
  TheadCell,
  TheadRow,
  TCellSeparator,
  BlueCell,
  TBodyCustom,
  THCellCustom
} from './settlement.styled'
import { Images } from '../../assets'
import { Colors, FontSize } from '../../styles'
import { GET_ATTACHMENT_FILE } from '../../redux/actions'
import {
  ConvertLocalTimeGet,
  ConvertLocalTimePostUTC,
  Lookup,
  messageErrorApproval,
  GetDurationHours,
  GetDurationInMinutes,
  durationToFormattedString
} from '../../utils'

const SettlementModal = (props) => {
  const {
    data: dataSr,
    isEdit,
    isInternal,
    pathRole,
    dataRevise: dataRevise
  } = props
  const { appReducer: appSize } = useSelector((state) => state)
  const { usersProfileReducer } = useSelector((state) => state)
  const [menu, setMenu] = useState('sr')
  const [fileEvidence, setFileEvidence] = useState([])
  const [dataContract, setDataContract] = useState([])
  const [canConfirm, setCanConfirm] = useState(false)
  const [needEvidence, setNeedEvidence] = useState(false)
  const [openEvidence, setOpenEvidence] = useState(false)
  const [openEvidenceRevise, setOpenEvidenceRevise] = useState(false)
  const [dataNotif, setDataNotif] = useState({})
  const [showAlert, setAlert] = useState({
    visible: false,
    msg: '',
    success: false
  })
  const [isPrintModal, setIsPrintModal] = useState(false)
  const [dataPrint, setDataPrint] = useState({})
  let pdfExportComponent = useRef(null)
  const [doRevise, setDoRevise] = useState(false)
  const [doConfirm, setDoConfirm] = useState(false)
  const [fileSize, setFileSize] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [remarksRevise, setRemarksRevise] = useState('')
  const [isClicked, setIsClicked] = useState(false)

  const [unfinalized, setUnfinalized] = useState(false)
  const [unfinalizedText, setUnfinalizedText] = useState(false)
  const [wantToUnfinalized, setWantUnfinalized] = useState(false)
  const [showUnfinalizedButton, setShowUnfinalizedButton] = useState(false)
  const [confirmationUnfinalized, setConfirmationUnfinalized] = useState(false)

  const toggleConfirmationUnfinalized = async () => {
    setConfirmationUnfinalized(!confirmationUnfinalized)
  }

  useEffect(() => {
    _getFileSize()
    _getConfirmable()
    _getRoleMapping()
  }, [])

  useEffect(() => {
    _getDataPrint()
  }, [dataSr])

  const signForm = useFormik({
    initialValues: {
      template_type: 'electronic_sign'
    },
    enableReinitialize: true,
    validateOnChange: true,
    validate: (values) => {
      console.log('values', values)
    }
  })

  const inputSelectOptions = [
    {
      key: 'electronic_sign',
      value: 'Electronic Sign'
    },
    {
      key: 'activity_detail',
      value: 'Activity Detail'
    },
    {
      key: 'wet_sign',
      value: 'Wet Sign'
    }
  ]

  const _getConfirmable = async () => {
    try {
      const filter = `[{"field":"code_ref","type":"text","value":"${dataSr?.Sr_No}"},
      {"field":"is_action_taken","type":"bool","value":"false"},
      {"field":"Notification_Type_Text","type":"text","value":"confirmation"}]`
      const { data } = await SettlementAPI.GetNotification(filter)

      const { ApprovalLevelText } = usersProfileReducer

      if (data && data?.length) {
        setCanConfirm(true)
        setDataNotif(data[0])
      } else if (
        dataSr?.Sr_Status?.includes('pproval') &&
        !dataSr?.Sr_Status?.includes('ompleted') &&
        ApprovalLevelText?.length > 0 &&
        ApprovalLevelText?.find(
          (e) => e?.Approval_Level_Text === 'Delegation Approval'
        )
      ) {
        setCanConfirm(true)
        setDataNotif({
          ...dataNotif,
          Approval_Level: 'Delegation Approval'
        })
        setNeedEvidence(!needEvidence)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getFileSize = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"File_Size"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllFromTableEnum(0, 2, filter)
      setFileSize(parseInt(data[0]?.Value))
    } catch (error) {
      console.log(error)
    }
  }

  const _getDataContract = async (clientCode) => {
    try {
      const response = await LintasGeneralAPI.GetDataContract(clientCode)
      return Promise.resolve(response?.data?.message?.Contracts || [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const _getDataActivity = async (srCode) => {
    try {
      const response = await ExecutionAPI.getActivityList(srCode)
      return Promise.resolve(response?.data?.message?.data || [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const _getDataCargo = async (srNo) => {
    try {
      const response = await ExecutionAPI.getCargoData(srNo)
      return Promise.resolve(response?.data?.data || [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const _getDataCargoTotal = async (srNo) => {
    try {
      const response = await SettlementAPI.GetCargoTotalData(srNo)
      return Promise.resolve(response?.data || [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const _getPrintData = async (srCode) => {
    try {
      const response = await SettlementAPI.GetPrintData(srCode)
      return Promise.resolve(response || [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const _getPrintApprovalData = async (srNo) => {
    try {
      const response = await SettlementAPI.GetPrintApprovalData(srNo)
      return Promise.resolve(response || [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const _getDataPrint = async () => {
    try {
      const response = await _getPrintData(dataSr?.Code)
      const responseApproval = await _getPrintApprovalData(dataSr?.Sr_No)
      const data = response?.data?.data
      const dataApproval = responseApproval?.data?.data
      const contractList = await _getDataContract(data?.Client_Code)
      setDataContract(contractList)
      const contract = contractList?.find(
        (c) => c?.SapContractNo === data?.Contract_No
      )
      const result = { ...data, Contract_No: contract?.AgreementNo }
      const dataActivityComplete = await _getDataActivity(dataSr?.Code)
      const dataCargo = await _getDataCargo(dataSr?.Sr_No)
      const dataCargoTotal = await _getDataCargoTotal(dataSr?.Sr_No)
      let rowTotal = []
      if (
        dataCargoTotal?.dataCountQty >= dataCargoTotal?.dataCountWeight &&
        dataCargoTotal?.dataCountQty >= dataCargoTotal?.dataCountVolume
      ) {
        rowTotal = dataCargoTotal?.data?.qty
      } else if (
        dataCargoTotal?.dataCountWeight >= dataCargoTotal?.dataCountQty &&
        dataCargoTotal?.dataCountWeight >= dataCargoTotal?.dataCountVolume
      ) {
        rowTotal = dataCargoTotal?.data?.weight
      } else {
        rowTotal = data?.data?.volume
      }

      setDataPrint({
        ...result,
        cargoList: dataCargo,
        cargoTotal: dataCargoTotal?.data,
        rowCargoTotal: rowTotal,
        activityList: dataActivityComplete,
        Settlement_Approval: dataApproval?.Settlement_Approval,
        ['Jetty_Name_Index']: data?.Jetty_Name?.replace('Jetty ', '')
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const _getRoleMapping = () => {
    const role_mapping = JSON.parse(localStorage.getItem('role_mapping'))
    const hasAccess = role_mapping?.find(
      (item) => item?.ModuleDescription === 'Settlement' && item?.AllowRollback
    )
    setShowUnfinalizedButton(
      hasAccess &&
        hasAccess?.AllowRollback &&
        dataSr?.Dws_Status_Text !== 'Debit Memo'
        ? true
        : false
    )
  }

  const _handleSettlementPrint = async () => {
    setIsPrintModal(true)
  }

  const SettlementEvidenceFormRevise = useFormik({
    enableReinitialize: true,
    initialValues: {
      RejectRemarks: ''
    },
    validationSchema: Yup.object({
      RejectRemarks: Yup.string().required('Required*')
    }),
    onSubmit: async (values) => {
      setIsClicked(true)
      let data = {}
      Object.keys(values)
        .filter((val) => val == 'RejectRemarks')
        .forEach((vl) => {
          data[vl] = values[vl]
        })
      _handleSettlementRevise(data)
    }
  })
  const SettlementEvidenceFormApprove = useFormik({
    enableReinitialize: true,
    initialValues: {
      RejectRemarks: ''
    },
    validationSchema: Yup.object({
      RejectRemarks: Yup.string().required('Required*')
    }),
    onSubmit: async (values) => {
      setIsClicked(true)
      let data = {}
      Object.keys(values)
        .filter((val) => val == 'RejectRemarks')
        .forEach((vl) => {
          data[vl] = values[vl]
        })
      _handleSettlementConfirm(data)
    }
  })

  const _handleSettlementSubmit = async () => {
    try {
      const dataObj = {
        SR_No: dataSr?.Sr_No,
        SR_Status_Code: dataSr?.Sr_Status_Code,
        Location_Code: parseInt(dataSr?.Location_Code),
        Action: 'Approved',
        Approval_Level: '',
        Remarks:
          [
            'Completed',
            'Completed without Approval',
            'Pre Finalized'
          ]?.includes(dataSr?.Sr_Status) && dataSr?.Revised_Remarks
            ? ''
            : dataSr?.Revised_Remarks,
        Modified_Date: ConvertLocalTimePostUTC(new Date())
      }
      const response = await SettlementAPI.SubmitSettlement(dataObj)
      if (response?.data?.message == '') {
        setAlert({
          msg: 'Data Has Been Submitted',
          success: true,
          visible: true
        })
      } else {
        setAlert({
          msg: messageErrorApproval(response?.data?.message ?? ''),
          success: false,
          visible: true
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _settlementConfirmCheck = () => {
    if (needEvidence) {
      setOpenEvidence(!openEvidence)
    } else {
      _handleSettlementConfirm()
    }
  }

  const _handleUnfinalized = async (remarks) => {
    try {
      const dataObj = {
        Remarks: remarks,
        File_Evidence: '',
        Approval_Level: '',
        SR_No: dataSr?.Sr_No,
        SR_Status_Code: dataSr?.Sr_Status_Code,
        Location_Code: parseInt(dataSr?.Location_Code),
        Action: unfinalizedText === 'With Approval' ? 'Revised' : 'Rejected',
        Modified_Date: ConvertLocalTimePostUTC(new Date())
      }
      const response = await SettlementAPI.ConfirmSettlement(dataObj)
      if (response?.data?.message == '') {
        setWantUnfinalized(false)
        setAlert({
          msg: 'Data Has Been Unfinalized',
          success: true,
          visible: true
        })
      } else {
        setAlert({
          msg: messageErrorApproval(response?.data?.message ?? ''),
          success: false,
          visible: true
        })
        throw new Error(response)
      }
    } catch (error) {
      console.error(error)
      setAlert({
        visible: true,
        success: false,
        msg: 'Oops, Something went wrong! Please try again later'
      })
    }
  }

  const _handleSettlementConfirm = async (values) => {
    try {
      const dataObj = {
        Approval_Level: '',
        SR_No: dataSr?.Sr_No,
        SR_Status_Code: dataSr?.Sr_Status_Code,
        Location_Code: parseInt(dataSr?.Location_Code),
        Remarks: values ? values?.RejectRemarks : '',
        Action: 'Approved',
        Modified_Date: ConvertLocalTimePostUTC(new Date()),
        File_Evidence:
          fileEvidence?.length > 0 ? fileEvidence[0]?.Code : 'Delegation'
      }
      const response = await SettlementAPI.ConfirmSettlement(dataObj)
      if (response?.data?.message == '') {
        setDoConfirm(false)
        setOpenEvidence(false)
        setAlert({
          msg: 'Data Has Been Approved',
          success: true,
          visible: true
        })
      } else {
        setAlert({
          msg: messageErrorApproval(response?.data?.message ?? ''),
          success: false,
          visible: true
        })
      }
      setIsClicked(false)
    } catch (error) {
      setIsClicked(false)
      console.log(error)
    }
  }

  const _handleSettlementRevise = async (values) => {
    try {
      const dataObj = {
        Approval_Level: '',
        SR_No: dataSr?.Sr_No,
        SR_Status_Code: dataSr?.Sr_Status_Code,
        Location_Code: parseInt(dataSr?.Location_Code),
        Action: 'Revised',
        Remarks: values?.RejectRemarks,
        Modified_Date: ConvertLocalTimePostUTC(new Date()),
        File_Evidence:
          fileEvidence?.length > 0 ? fileEvidence[0]?.Code : 'Delegation'
      }
      const response = await SettlementAPI.ConfirmSettlement(dataObj)
      if (response?.data?.message == '') {
        setDoRevise(false)
        setOpenEvidenceRevise(false)
        setAlert({
          msg: 'Data has been Returned to be Revised',
          success: true,
          visible: true
        })
      } else {
        setAlert({
          msg: messageErrorApproval(response?.data?.message ?? ''),
          success: false,
          visible: true
        })
      }
      setIsClicked(false)
    } catch (error) {
      setIsClicked(false)
      console.log(error)
    }
  }

  const closeExeModal = () => {
    SettlementEvidenceFormRevise.resetForm()
    SettlementEvidenceFormApprove.resetForm()

    setDoConfirm(false)
    setDoRevise(false)
  }

  const _exportPDFWithComponent = () => {
    pdfExportComponent.save()
  }
  const uploadingFileToServer = async (formData, callback) => {
    console.time()
    setIsLoading(true)

    // /*
    try {
      const response = await SettlementAPI.UploadAttachments(formData)
      callback(response)
      setIsLoading(false)

      await dispatchEvent({
        type: GET_ATTACHMENT_FILE,
        payload: { dataAttachment: response?.data?.data }
      })
    } catch (err) {
      return err
    }
    // */
  }

  const approvalGrid = (settlementApproval) => {
    if (signForm?.values?.template_type === 'wet_sign') {
      return ['Control Room', 'Project Manager', 'Client']?.map(
        (val, index) => (
          <WetSignRow key={index}>
            <td
              style={{
                padding: ' 0px 20px',
                fontSize: '16px',
                textAlign: 'left'
              }}
            >
              {val}
            </td>
            <td style={{ padding: ' 0px 20px', textAlign: 'left' }}></td>
            <td style={{ padding: ' 0px 20px', textAlign: 'left' }}></td>
            <td style={{ padding: ' 0px 20px', textAlign: 'left' }}></td>
          </WetSignRow>
        )
      )
    }

    return settlementApproval?.map((e, i) => {
      return (
        <tr key={i}>
          <TdStyled className='padded-align-left'>{e?.Confirmer_Name}</TdStyled>
          <TdStyled className='padded-align-left'></TdStyled>
          <TdStyled className='padded-align-left'>
            <BackgroundSign>
              <div
                style={{
                  fontSize: '16px',
                  textAlign: 'left'
                }}
              >
                Digital Signed By {e?.Confirmer_Name} <br />
                Date :
                {ConvertLocalTimeGet(
                  dataSr?.Location,
                  e?.Confirmer_Date,
                  'DD-MM-YYYY'
                )}{' '}
                {ConvertLocalTimeGet(
                  dataSr?.Location,
                  e?.Confirmer_Date,
                  'HH:mm'
                )}
              </div>
            </BackgroundSign>
          </TdStyled>
        </tr>
      )
    })
  }

  return (
    <>
      {showAlert.visible && (
        <Alerts
          id='settlement-confirm-submit-alert'
          msg={showAlert.msg}
          close={() => {
            setAlert({ msg: '', success: true, visible: false })
            props.closeSettlementModal(!props.showModal)
          }}
          open={showAlert.visible}
          success={showAlert.success}
        />
      )}
      <MasterModal
        width={100}
        height={925}
        maxWidth='lg' // Will be removed later if stable
        isEdit={isEdit}
        isStatic={true}
        isSrModal={true}
        fullWidth={true}
        heightType={'px'}
        title='Settlement'
        canConfirm={canConfirm}
        allowPrint={pathRole?.allowPrint}
        isSettlementPage={true}
        isReviseSettlement={!_.isEmpty(dataSr && dataSr?.Revised_Remarks)}
        showModal={props.showModal}
        handlePrint={_handleSettlementPrint}
        handleSubmit={_handleSettlementSubmit}
        handleConfirm={() => {
          if (needEvidence) {
            setOpenEvidence(!openEvidence)
          } else {
            setDoConfirm(true)
          }
        }}
        handleRevise={() => {
          if (needEvidence) {
            setOpenEvidenceRevise(!openEvidenceRevise)
          } else {
            setDoRevise(true)
          }
        }}
        subTitle={isEdit ? 'Edit Settlement' : 'Detail Settlement'}
        handleClose={() => props.closeSettlementModal(!props.showModal)}
        subSubTitle={dataSr?.Sr_No}
        status={dataSr?.Sr_Status}
        unfinalized={unfinalized}
        setUnfinalized={setUnfinalized}
        setUnfinalizedText={setUnfinalizedText}
        setWantUnfinalized={setWantUnfinalized}
        showUnfinalizedButton={showUnfinalizedButton}
        isUnfinalizedsettlement={
          dataSr?.Sr_Status === 'Finalized' &&
          dataSr?.Dws_Status_Text !== 'Debit Memo'
        }
        isFinalizedSettlement={
          !_.isEmpty(dataSr?.Revised_Remarks) &&
          dataSr?.Sr_Status === 'Pre Finalized'
        }
        isResubmitSettlement={
          !_.isEmpty(dataSr?.Revised_Remarks) &&
          ['Completed', 'Completed without Approval'].includes(
            dataSr?.Sr_Status
          )
        }
      >
        {wantToUnfinalized && (
          <ModalUnfinalized
            params={props}
            unfinalizedText={unfinalizedText}
            wantToUnfinalized={wantToUnfinalized}
            handleUnfinalized={_handleUnfinalized}
            handleWantToUnfinalized={setWantUnfinalized}
            showModalConfirmation={confirmationUnfinalized}
            handleCloseConfirmation={() => toggleConfirmationUnfinalized()}
          />
        )}
        {doRevise && (
          <ModalReject
            typeModal='revise'
            typeLog={Lookup.LOG_MODULE.SETTLEMENT}
            subtypeLog={Lookup.LOG_SUB_MODULE.REVISE}
            srCode={dataSr?.Code}
            isClicked={isClicked}
            ServiceRequestRejectForm={SettlementEvidenceFormRevise}
            rejectText='Are you sure to revise?'
            toggleDialog={() => closeExeModal()}
          />
        )}
        {doConfirm && (
          <ModalApprove
            handleApprove={() => {
              setIsClicked(true)
              _settlementConfirmCheck()
            }}
            isClicked={isClicked}
            approveText='Are you sure to approve?'
            toggleDialog={() => closeExeModal()}
          />
        )}
        {openEvidence && (
          <ModalEvidence
            Submit={'Approve'}
            textEvidence={'please upload the evidence'}
            textEvidenceConfirmation={'Are you sure to approve'}
            isLoading={isLoading}
            SettlementEvidenceForm={SettlementEvidenceFormApprove}
            setAlert={setAlert}
            isClicked={isClicked}
            toggleDialog={() => {
              SettlementEvidenceFormApprove.resetForm()
              setFileEvidence([])
              setOpenEvidence(!openEvidence)
            }}
            submitEvidence={_handleSettlementConfirm}
            remarksRevise={remarksRevise}
            setRemarksRevise={setRemarksRevise}
            uploadingFileToServer={uploadingFileToServer}
            setFileEvidence={setFileEvidence}
            fileEvidence={fileEvidence}
            fileDetail={{
              type: '.doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png',
              name: 'filename',
              size: fileSize
            }}
          />
        )}

        {openEvidenceRevise && (
          <ModalEvidence
            Submit={'Revise'}
            textEvidence={'please upload the evidence'}
            textEvidenceConfirmation={'Are you sure to revise'}
            isLoading={isLoading}
            isClicked={isClicked}
            SettlementEvidenceForm={SettlementEvidenceFormRevise}
            setAlert={setAlert}
            toggleDialog={() => {
              SettlementEvidenceFormRevise.resetForm()
              setFileEvidence([])
              setOpenEvidenceRevise(!openEvidenceRevise)
            }}
            submitEvidence={_handleSettlementRevise}
            remarksRevise={remarksRevise}
            setRemarksRevise={setRemarksRevise}
            uploadingFileToServer={uploadingFileToServer}
            setFileEvidence={setFileEvidence}
            fileEvidence={fileEvidence}
            fileDetail={{
              type: '.doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png',
              name: 'filename',
              size: fileSize
            }}
          />
        )}
        <Nav>
          <MenuWrapper style={{ flex: 'auto' }}>
            <MenuList
              id='settlement-modal-menu-sr-btn'
              active={menu === 'sr'}
              onClick={() => setMenu('sr')}
            >
              Service Request
            </MenuList>
            <MenuList
              active={menu === 'cargo-equipment'}
              onClick={() => setMenu('cargo-equipment')}
              id='settlement-modal-menu-cargo-equipment-labour-btn'
            >
              Cargo &amp; Equipment/Labour
            </MenuList>
            <MenuList
              id='settlement-modal-menu-activity-btn'
              active={menu === 'activity'}
              onClick={() => setMenu('activity')}
            >
              Activity
            </MenuList>
            <MenuList
              id='settlement-modal-menu-delay-btn'
              active={menu === 'delay'}
              onClick={() => setMenu('delay')}
            >
              Delay
            </MenuList>
            <MenuList
              id='settlement-modal-menu-doc-btn'
              active={menu === 'doc'}
              onClick={() => setMenu('doc')}
            >
              Document
            </MenuList>
          </MenuWrapper>
          {/* <div>
            {pathRole.allowPrint && (
              <Button
                type='submit'
                color='blue'
                id='mdl_dialog_print_btn'
                style={{ float: 'right', marginTop: 4, marginRight: 30, fontSize: FontSize.small }}
                onClick={_handleSettlementPrint}
              >
                <PrintOutlined style={{ marginRight: 10 }} />
                PRINT
              </Button>
            )}
          </div> */}
        </Nav>
        <div style={{ height: '100%' }}>
          <ContentList active={menu === 'sr'} appSize={appSize}>
            <SrModalContent
              data={dataSr}
              isEdit={isEdit}
              dataRevise={dataRevise}
              isInternal={isInternal}
              dataContract={dataContract}
            />
          </ContentList>
          <ContentList active={menu === 'cargo-equipment'} appSize={appSize}>
            <CargoEquipmentModalContent isEdit={isEdit} dataAll={dataSr} dataContract={dataContract} />
          </ContentList>
          {/* <ContentList active={menu === 'prod'} appSize={appSize}>
            <ProdModalContent data={dataSr} isEdit={isEdit} pathRole={pathRole} />
          </ContentList> */}
          <ContentList active={menu === 'activity'} appSize={appSize}>
            {menu === 'activity' && (
              <ActivityModalContent inEditMode={isEdit} dataAll={dataSr} />
            )}
          </ContentList>
          <ContentList active={menu === 'delay'} appSize={appSize}>
            {menu === 'delay' && (
              <DelayModalContent data={dataSr} inEditMode={isEdit} />
            )}
          </ContentList>
          <ContentList active={menu === 'doc'} appSize={appSize}>
            <DocModalContent
              data={dataSr}
              isEdit={isEdit}
              pathRole={pathRole}
              fileSize={fileSize}
            />
          </ContentList>
        </div>
        <MasterModal
          width={70}
          maxWidth='xl'
          isEdit={isEdit}
          isStatic={true}
          isSrModal={true}
          fullWidth={true}
          isPrintPreview={true}
          title='Print Preview'
          showModal={isPrintModal}
          status={dataSr?.Sr_Status}
          handlePrint={_exportPDFWithComponent}
          handleClose={() => setIsPrintModal(false)}
          showUnfinalizedButton={showUnfinalizedButton}
        >
          <div style={{ marginBottom: '20px', height: '800px' }}>
            <InputSelectSignWrapper>
              <InputSelectWrapperInner>
                <InputSelectText>Template Type</InputSelectText>
                <InputSelectWrapper>
                  <InputSelect
                    form={signForm}
                    isTitle={false}
                    name='template_type'
                    optionText='value'
                    placeholder='Template Type'
                    optionValueKey='key'
                    style={{ fontSize: '0.75rem' }}
                    options={inputSelectOptions}
                    id='settlement-printout-select-sign'
                  />
                </InputSelectWrapper>
              </InputSelectWrapperInner>
              <Button
                type='submit'
                color='green'
                id='mdl_dialog_approve_btn'
                style={{ marginRight: 10, fontSize: FontSize.small }}
                onClick={_exportPDFWithComponent}
              >
                <GetAppRoundedIcon />
                Download
              </Button>
            </InputSelectSignWrapper>
            <PDFExport
              ref={(component) => (pdfExportComponent = component)}
              paperSize={'A4'}
              margin={20}
              fileName={`Report SR ${dataPrint?.Sr_No}`}
              author='KendoReact Team'
              landscape={true}
              scale={0.5}
              forcePageBreak='.page-break'
              pageTemplate={() => {
                return (
                  <div>
                    <div style={{ fontSize: '16px', margin: '20% 30px 0px' }}>
                      Print Out Date : {moment().format('DD MMMM YYYY')}
                    </div>
                  </div>
                )
              }}
            >
              <div>
                {' '}
                <Header>
                  <VesselSchedule>Service Request</VesselSchedule>
                  <ImageHeader src={Images.Beyond} />
                </Header>
                <SubHeader>
                  <div>
                    <WrapperData>
                      <Title>Date</Title>
                      <div>
                        <span style={{ marginRight: 5 }}>:</span>
                        {ConvertLocalTimeGet(
                          dataSr?.Location,
                          dataSr?.Created_Date_No_Format,
                          'DD MMMM YYYY HH:mm'
                        )}
                      </div>
                    </WrapperData>
                    <WrapperData>
                      <Title>Service Request No</Title>
                      <div style={{ color: Colors.softBlue }}>
                        <span style={{ marginRight: 5 }}>:</span>
                        {dataPrint?.Sr_No}
                      </div>
                    </WrapperData>
                    <WrapperData>
                      <Title>Service Request Type</Title>
                      <div>
                        <span style={{ marginRight: 5 }}>:</span>
                        {dataPrint?.Sr_Type}
                      </div>
                    </WrapperData>
                    <WrapperData>
                      <Title>Location</Title>
                      <div>
                        <span style={{ marginRight: 5 }}>:</span>
                        {dataPrint?.Location}
                      </div>
                    </WrapperData>
                  </div>
                  <div
                    style={{ margin: '0px 30px 0px 0px', minWidth: '500px' }}
                  >
                    <WrapperData>
                      <Title>Client Representative</Title>
                      <div style={{ display: 'flex', maxWidth: '290px' }}>
                        <div style={{ marginRight: 5 }}>:</div>
                        <div>{dataPrint?.Client_Representative}</div>
                      </div>
                    </WrapperData>
                    <WrapperData>
                      <Title>Client to be Invoiced</Title>
                      <div style={{ display: 'flex', maxWidth: '290px' }}>
                        <div style={{ marginRight: 5 }}>:</div>
                        <div> {dataPrint?.Client}</div>
                      </div>
                    </WrapperData>
                    <WrapperData>
                      <Title>Contract No</Title>
                      <div>
                        <span style={{ marginRight: 5 }}>:</span>
                        {dataPrint?.Contract_No}
                      </div>
                    </WrapperData>
                    <WrapperData>
                      <Title>Service Request Status</Title>
                      <div style={{ color: Colors.softBlue }}>
                        <span style={{ marginRight: 5 }}>:</span>
                        {dataPrint?.Sr_Status}
                      </div>
                    </WrapperData>
                  </div>
                </SubHeader>
                <div style={{ margin: '30px 30px 0px' }}>
                  {dataPrint?.Sr_Type !== 'Vessel Alongside' ? (
                    <>
                      <Table>
                        <Thead>
                          <TheadRow>
                            {signForm?.values?.template_type === 'activity_detail' && (
                              <TheadCell width='500px'>Description</TheadCell>
                            )}
                            {signForm?.values?.template_type !== 'activity_detail' && (<>
                              <TheadCell width='150px'>Activity/Delay</TheadCell>
                              <TheadCell width='200px'>Description</TheadCell>
                              <TheadCell width='200px'>Unit Name</TheadCell>
                              <TheadCell width='130px'>Type</TheadCell>
                              <TheadCell width='50px'>Qty</TheadCell>
                            </>)}
                            <TheadCell width='150px'>Start</TheadCell>
                            <TheadCell width='150px'>Finish</TheadCell>
                            <TheadCell width='90px'>Breaktime</TheadCell>
                            <TheadCell width={signForm?.values?.template_type === 'activity_detail' ? '360px' : 'auto'}>Remark</TheadCell>
                            <TheadCell width={signForm?.values?.template_type === 'activity_detail' ? '175px' : '120px'}>
                              Duration in Hours
                            </TheadCell>
                            <TheadCell width='120px'>
                              Duration in Days
                            </TheadCell>
                          </TheadRow>
                        </Thead>
                        <Tbody>
                          {dataPrint?.activityList?.map(
                            (activity, activityIndex) => {
                              const taskHeaderList =
                                activity?.progress_task_header_list || []


                              if (taskHeaderList?.length === 0) {
                                return
                              }

                              return (
                                <>
                                  <TheadRow
                                    className='prevent-split'
                                    key={activity?.activity_name}
                                    id={`settlement-printout-activity-table-row-${activity?.activity_name}`}
                                  >
                                    <TbodyCell colSpan={5}>
                                      {activity?.activity_name}
                                    </TbodyCell>
                                  </TheadRow>

                                  {taskHeaderList?.map((taskHeader, i) => {
                                    const taskDetailList =
                                      taskHeader?.progress_task_detail_list ||
                                      []
                                    const taskDelayList =
                                      taskHeader?.progress_delay_list || []
                                    const firstTaskDetailWithinATaskHeader =
                                      taskDetailList[0]
                                    const firstDelayWithinATaskHeader =
                                      taskDelayList[0]
                                    let delayDurationHour = ''
                                    if(signForm?.values?.template_type !== 'activity_detail') {
                                      delayDurationHour =
                                      firstDelayWithinATaskHeader &&
                                      firstDelayWithinATaskHeader?.delay_duration
                                        ? `${parseFloat(
                                            firstDelayWithinATaskHeader?.delay_duration /
                                              60
                                          )?.toFixed(1)} Hours`
                                        : ''
                                    } else {
                                      delayDurationHour =
                                      firstDelayWithinATaskHeader &&
                                      firstDelayWithinATaskHeader?.delay_duration
                                        ? durationToFormattedString(firstDelayWithinATaskHeader?.delay_duration)
                                        : ''
                                    }
                                    const delayDurationDay =
                                      firstDelayWithinATaskHeader &&
                                      firstDelayWithinATaskHeader?.delay_duration
                                        ? `${parseFloat(
                                            firstDelayWithinATaskHeader?.delay_duration /
                                              60 /
                                              24
                                          )?.toFixed(1)} Days`
                                        : ''

                                    const isEmptyDetail = taskDetailList.length === 0

                                    const durationDetail = GetDurationHours(
                                      firstTaskDetailWithinATaskHeader?.billing_start_time,
                                      firstTaskDetailWithinATaskHeader?.billing_finish_time,
                                      firstTaskDetailWithinATaskHeader?.break_time
                                    )

                                    let durationDetailHour = ''
                                    if(signForm?.values?.template_type !== 'activity_detail') {
                                      if(!isEmptyDetail) {
                                        durationDetailHour =
                                        durationDetail &&
                                        firstTaskDetailWithinATaskHeader &&
                                        firstTaskDetailWithinATaskHeader?.qty
                                          ? `${parseFloat(
                                              durationDetail *
                                                firstTaskDetailWithinATaskHeader?.qty
                                            ).toFixed(1)} Hours`
                                          : ''
                                      } else {
                                        durationDetailHour = `${parseFloat(
                                          taskHeader?.billng_dur_time / 60
                                        ).toFixed(1)} Hours`
                                      }
                                    } else {
                                      if(!isEmptyDetail) {
                                        const durationDetailInMinutes = GetDurationInMinutes(
                                          firstTaskDetailWithinATaskHeader?.billing_start_time,
                                          firstTaskDetailWithinATaskHeader?.billing_finish_time,
                                          firstTaskDetailWithinATaskHeader?.break_time
                                        )
                                        durationDetailHour =
                                        durationDetailInMinutes &&
                                        firstTaskDetailWithinATaskHeader &&
                                        firstTaskDetailWithinATaskHeader?.qty
                                          ? durationToFormattedString(durationDetailInMinutes*firstTaskDetailWithinATaskHeader?.qty)
                                          : ''
                                      } else {
                                        durationDetailHour = durationToFormattedString(taskHeader?.billng_dur_time)
                                      }
                                    }

                                    const durationDetailDay =
                                      durationDetail &&
                                      firstTaskDetailWithinATaskHeader &&
                                      firstTaskDetailWithinATaskHeader?.qty
                                        ? `${parseFloat(
                                            (durationDetail *
                                              firstTaskDetailWithinATaskHeader?.qty) /
                                              24
                                          ).toFixed(1)} Days`
                                        : ''

                                    return (
                                      <>
                                        <TheadRow
                                          className='prevent-split'
                                          key={
                                            firstTaskDetailWithinATaskHeader?.code
                                          }
                                          id={`settlement-printout-activity-table-row-${firstTaskDetailWithinATaskHeader?.code}`}
                                        >
                                          <TbodyCell
                                            className='vertical-align'
                                            style={{ padding: '10px 25px 10px 10px' }}
                                            width={signForm?.values?.template_type === 'activity_detail' ? '500px' : '150px'}>
                                            {(i +1) + '. ' + taskHeader?.task}
                                          </TbodyCell>
                                          {signForm?.values?.template_type !== 'activity_detail' && (<>
                                            <TbodyCell>
                                            {
                                              firstTaskDetailWithinATaskHeader?.description
                                            }
                                          </TbodyCell>
                                          <TbodyCell>
                                            {
                                              firstTaskDetailWithinATaskHeader?.unit_name_code
                                            }{' '}
                                            {firstTaskDetailWithinATaskHeader &&
                                              '-'}{' '}
                                            {
                                              firstTaskDetailWithinATaskHeader?.unit_name_description
                                            }
                                          </TbodyCell>
                                          <TbodyCell>
                                            {
                                              firstTaskDetailWithinATaskHeader?.material
                                            }
                                          </TbodyCell>
                                          <TbodyCell>
                                            {
                                              firstTaskDetailWithinATaskHeader?.qty
                                            }
                                          </TbodyCell>
                                          </>)}
                                          <TbodyCell width='150px'>
                                            {ConvertLocalTimeGet(
                                              dataSr.Location,
                                              firstTaskDetailWithinATaskHeader?.billing_start_time || taskHeader?.billng_start_time,
                                              'DD/MM/YYYY HH:mm'
                                            )}
                                          </TbodyCell>
                                          <TbodyCell width='150px'>
                                            {ConvertLocalTimeGet(
                                              dataSr.Location,
                                              firstTaskDetailWithinATaskHeader?.billing_finish_time || taskHeader?.billng_finish_time,
                                              'DD/MM/YYYY HH:mm'
                                            )}
                                          </TbodyCell>
                                          <TbodyCell width='90px'>
                                            {
                                              firstTaskDetailWithinATaskHeader?.break_time  || 0
                                            }
                                          </TbodyCell>
                                          <TbodyCell
                                            className='vertical-align'
                                            width={signForm?.values?.template_type === 'activity_detail' ? '360px' : 'auto'}>
                                            {
                                              firstTaskDetailWithinATaskHeader?.remarks
                                            }
                                          </TbodyCell>
                                          <BlueCell
                                            width={signForm?.values?.template_type === 'activity_detail' ? '175px' : '120px'}>
                                            {durationDetailHour}
                                          </BlueCell>
                                          <BlueCell width='120px'>
                                          {!isEmptyDetail ? durationDetailDay : `${parseFloat(
                                            taskHeader?.billng_dur_time / 1440
                                          ).toFixed(1)} Days`}

                                          </BlueCell>
                                        </TheadRow>
                                        {taskDetailList
                                          ?.slice(1)
                                          ?.map((taskDetail) => {
                                            const durationDetail =
                                              GetDurationHours(
                                                taskDetail?.start_time,
                                                taskDetail?.finish_time,
                                                taskDetail?.break_time
                                              )

                                            let durrHour = ''
                                            if(signForm?.values.template_type !== 'activity_detail') {
                                              durrHour =
                                              durationDetail && taskDetail?.qty
                                                ? `${parseFloat(
                                                    durationDetail *
                                                      taskDetail?.qty
                                                  )?.toFixed(1)} Hours`
                                                : ''
                                            } else {
                                              const durationDetailInMinutes =
                                              GetDurationInMinutes(
                                                taskDetail?.start_time,
                                                taskDetail?.finish_time,
                                                taskDetail?.break_time
                                              )
                                              durrHour =
                                              durationDetailInMinutes && taskDetail?.qty
                                                ? durationToFormattedString(durationDetailInMinutes*taskDetail?.qty)
                                                : ''
                                            }

                                            const durrDay =
                                              durationDetail && taskDetail?.qty
                                                ? `${parseFloat(
                                                    (durationDetail *
                                                      taskDetail?.qty) /
                                                      24
                                                  )?.toFixed(1)} Days`
                                                : ''

                                            return (
                                              <TheadRow
                                                className='prevent-split'
                                                key={taskDetail?.code}
                                                id={`settlement-printout-activity-table-row-${taskDetail?.code}`}
                                              >
                                                <TbodyCell
                                                  className='vertical-align'
                                                  width={signForm?.values?.template_type === 'activity_detail' ? '500px' : '150px'}>
                                                </TbodyCell>
                                                {signForm?.values?.template_type !== 'activity_detail' && (<>
                                                  <TbodyCell>
                                                    {taskDetail?.description}
                                                  </TbodyCell>
                                                  <TbodyCell>
                                                    {taskDetail?.unit_name_code} -{' '}
                                                    {
                                                      taskDetail?.unit_name_description
                                                    }
                                                  </TbodyCell>
                                                  <TbodyCell>
                                                    {taskDetail?.material}
                                                  </TbodyCell>
                                                  <TbodyCell>
                                                    {taskDetail?.qty}
                                                  </TbodyCell>
                                                </>)}
                                                <TbodyCell width='150px'>
                                                  {ConvertLocalTimeGet(
                                                    dataSr?.Location,
                                                    taskDetail?.billing_start_time,
                                                    'DD/MM/YYYY HH:mm'
                                                  )}
                                                </TbodyCell>
                                                <TbodyCell width='150px'>
                                                  {ConvertLocalTimeGet(
                                                    dataSr?.Location,
                                                    taskDetail?.billing_finish_time,
                                                    'DD/MM/YYYY HH:mm'
                                                  )}
                                                </TbodyCell>
                                                <TbodyCell width='90px'>
                                                  {taskDetail?.break_time}
                                                </TbodyCell>
                                                <TbodyCell
                                                  className='vertical-align'
                                                  width={signForm?.values?.template_type === 'activity_detail' ? '360px' : 'auto'}>
                                                  {taskDetail?.remarks}
                                                </TbodyCell>
                                                <BlueCell
                                                  width={signForm?.values?.template_type === 'activity_detail' ? '175px' : '120px'}>
                                                  {durrHour}
                                                </BlueCell>
                                                <BlueCell width='120px'>{durrDay}</BlueCell>
                                              </TheadRow>
                                            )
                                          })}
                                        {taskDelayList?.length > 0 && (
                                          <>
                                            <TbodyRow
                                              className='prevent-split'
                                              key={
                                                firstDelayWithinATaskHeader?.code
                                              }
                                              id={`settlement-printout-activity-table-row-${firstDelayWithinATaskHeader?.code}`}
                                            >
                                              <TbodyCell
                                                className='vertical-align'
                                                width={signForm?.values?.template_type === 'activity_detail' ? '500px' : '150px'}>
                                                Delay
                                              </TbodyCell>
                                              {signForm?.values?.template_type !== 'activity_detail' && (<>
                                                <TbodyCell>
                                                  {
                                                    firstDelayWithinATaskHeader?.delay_description
                                                  }
                                                </TbodyCell>
                                                <TbodyCell></TbodyCell>
                                                <TbodyCell>
                                                  {
                                                    firstDelayWithinATaskHeader?.delay_type_text
                                                  }
                                                </TbodyCell>
                                                <TbodyCell></TbodyCell>
                                              </>)}
                                              <TbodyCell width='150px'>
                                                {ConvertLocalTimeGet(
                                                  dataSr?.Location,
                                                  firstDelayWithinATaskHeader?.start_date,
                                                  'DD/MM/YYYY HH:mm'
                                                )}
                                              </TbodyCell>
                                              <TbodyCell width='150px'>
                                                {ConvertLocalTimeGet(
                                                  dataSr?.Location,
                                                  firstDelayWithinATaskHeader?.finish_date,
                                                  'DD/MM/YYYY HH:mm'
                                                )}
                                              </TbodyCell>
                                              <TbodyCell width='90px'></TbodyCell>
                                              <TbodyCell width={signForm?.values?.template_type === 'activity_detail' ? '360px' : 'auto'}>
                                                {
                                                  firstDelayWithinATaskHeader?.remarks
                                                }
                                              </TbodyCell>
                                              <BlueCell width={signForm?.values?.template_type === 'activity_detail' ? '175px' : '120px'}>
                                                {delayDurationHour}
                                              </BlueCell>
                                              <BlueCell width='120px'>
                                                {delayDurationDay}
                                              </BlueCell>
                                            </TbodyRow>
                                            {taskDelayList
                                              ?.slice(1)
                                              ?.map((taskDelay) => {
                                                let durrHour = ''
                                                if(signForm?.values?.template_type !== 'activity_detail') {
                                                  durrHour =
                                                  taskDelay &&
                                                  taskDelay?.delay_duration
                                                    ? `${parseFloat(
                                                        taskDelay?.delay_duration /
                                                          60
                                                      )?.toFixed(1)} Hours`
                                                    : ''
                                                } else {
                                                  durrHour =
                                                  taskDelay &&
                                                  taskDelay?.delay_duration
                                                    ? durationToFormattedString(taskDelay?.delay_duration)
                                                    : ''
                                                }
                                                const durrDay =
                                                  taskDelay &&
                                                  taskDelay?.delay_duration
                                                    ? `${parseFloat(
                                                        taskDelay?.delay_duration /
                                                          60 /
                                                          24
                                                      )?.toFixed(1)} Days`
                                                    : ''

                                                return (
                                                  <TheadRow
                                                    className='prevent-split'
                                                    key={taskDelay?.code}
                                                    id={`settlement-printout-activity-table-row-${taskDelay?.code}`}
                                                  >
                                                    <TbodyCell
                                                      className='vertical-align'
                                                      width={signForm?.values?.template_type === 'activity_detail' ? '500px' : '150px'}>
                                                    </TbodyCell>
                                                    {signForm?.values?.template_type !== 'activity_detail' && (<>
                                                      <TbodyCell>
                                                        {
                                                          taskDelay?.delay_description
                                                        }
                                                      </TbodyCell>
                                                      <TbodyCell></TbodyCell>
                                                      <TbodyCell>
                                                        {
                                                          taskDelay?.delay_type_text
                                                        }
                                                      </TbodyCell>
                                                      <TbodyCell></TbodyCell>
                                                    </>)}
                                                    <TbodyCell width='150px'>
                                                      {ConvertLocalTimeGet(
                                                        dataSr?.Location,
                                                        taskDelay?.start_date,
                                                        'DD/MM/YYYY HH:mm'
                                                      )}
                                                    </TbodyCell>
                                                    <TbodyCell width='150px'>
                                                      {ConvertLocalTimeGet(
                                                        dataSr?.Location,
                                                        taskDelay?.finish_date,
                                                        'DD/MM/YYYY HH:mm'
                                                      )}
                                                    </TbodyCell>
                                                    <TbodyCell width='90px'></TbodyCell>
                                                    <TbodyCell
                                                      className='vertical-align'
                                                      width={signForm?.values?.template_type === 'activity_detail' ? '360px' : 'auto'}>
                                                      {taskDelay?.remarks}
                                                    </TbodyCell>
                                                    <BlueCell width={signForm?.values?.template_type === 'activity_detail' ? '175px' : '120px'}>
                                                      {durrHour}
                                                    </BlueCell>
                                                    <BlueCell width='120px'>
                                                      {durrDay}
                                                    </BlueCell>
                                                  </TheadRow>
                                                )
                                              })}
                                          </>
                                        )}
                                      </>
                                    )
                                  })}
                                  {activityIndex !==
                                    dataPrint?.activityList?.length - 1 && (
                                    <TbodyRow className='prevent-split'>
                                      <TCellSeparator colSpan={11} />
                                    </TbodyRow>
                                  )}
                                </>
                              )
                            }
                          )}
                        </Tbody>
                      </Table>
                      <Table>
                        <Thead>
                          <TheadRow className='prevent-split'>
                            <TheadCell>Material</TheadCell>
                            <TheadCell>Detail</TheadCell>
                            <TheadCell>Qty</TheadCell>
                            <TheadCell>UoM Qty</TheadCell>
                            <TheadCell>Weight</TheadCell>
                            <TheadCell>UoM Weight</TheadCell>
                            <TheadCell>Volume</TheadCell>
                            <TheadCell>UoM Volume</TheadCell>
                            <TheadCell>Material Status</TheadCell>
                            <TheadCell>Material Date</TheadCell>
                          </TheadRow>
                        </Thead>
                        <Tbody>
                          {dataPrint?.cargoList?.map((item) => (
                            <TbodyRow
                              key={item?.code}
                              className='prevent-split'
                              id={`settlement-printout-material-table-row-${item?.code}`}
                            >
                              <TbodyCell>{item?.product_type}</TbodyCell>
                              <TbodyCell>{item?.product_name}</TbodyCell>
                              <TbodyCell>{item?.quantity}</TbodyCell>
                              <TbodyCell>{item?.uom_quantity}</TbodyCell>
                              <TbodyCell>{item?.weight}</TbodyCell>
                              <TbodyCell>{item?.uom_weight}</TbodyCell>
                              <TbodyCell>{item?.volume}</TbodyCell>
                              <TbodyCell>{item?.uom_volume}</TbodyCell>
                              <TbodyCell>{item?.material_status}</TbodyCell>
                              <TbodyCell>
                                {ConvertLocalTimeGet(
                                  dataSr?.Location,
                                  item?.material_date,
                                  'DD/MM/YYYY HH:mm'
                                )}
                              </TbodyCell>
                            </TbodyRow>
                          ))}
                        </Tbody>
                        <TBodyCustom>
                          {dataPrint?.cargoTotal?.weight === null &&
                          dataPrint?.cargoTotal?.volume === null ? (
                            <></>
                          ) : (
                            <>
                              {dataPrint?.rowCargoTotal?.map((item, index) => (
                                <TbodyRow id='total-material' key={index}>
                                  {index === 0 && (
                                    <THCellCustom
                                      scope='rowgroup colgroup'
                                      colSpan={2}
                                      rowSpan={dataPrint?.rowCargoTotal?.length}
                                    >
                                      Total
                                    </THCellCustom>
                                  )}
                                  <TbodyCell>
                                    {' '}
                                    {
                                      dataPrint?.cargoTotal?.qty[index]
                                        ?.total_qty
                                    }{' '}
                                  </TbodyCell>
                                  <TbodyCell>
                                    {' '}
                                    {
                                      dataPrint?.cargoTotal?.qty[index]?.uom_qty
                                    }{' '}
                                  </TbodyCell>
                                  <TbodyCell>
                                    {' '}
                                    {
                                      dataPrint?.cargoTotal?.weight[index]
                                        ?.total_weight
                                    }{' '}
                                  </TbodyCell>
                                  <TbodyCell>
                                    {' '}
                                    {
                                      dataPrint?.cargoTotal?.weight[index]
                                        ?.uom_weight
                                    }{' '}
                                  </TbodyCell>
                                  <TbodyCell>
                                    {' '}
                                    {
                                      dataPrint?.cargoTotal?.volume[index]
                                        ?.total_volume
                                    }{' '}
                                  </TbodyCell>
                                  <TbodyCell>
                                    {' '}
                                    {
                                      dataPrint?.cargoTotal?.volume[index]
                                        ?.uom_volume
                                    }{' '}
                                  </TbodyCell>
                                  <TbodyCell></TbodyCell>
                                  <TbodyCell></TbodyCell>
                                </TbodyRow>
                              ))}
                            </>
                          )}
                        </TBodyCustom>
                      </Table>
                    </>
                  ) : (
                    <Table>
                      <Thead>
                        <TheadRow style={{ height: '50px' }}>
                          <TheadCell style={{ width: '170px' }}>
                            Vessel Name
                          </TheadCell>
                          <TheadCell style={{ width: '105px' }}>
                            Berth Number
                          </TheadCell>
                          <TheadCell style={{ width: '175px' }}>
                            Length Overall (Meters)
                          </TheadCell>
                          <TheadCell style={{ width: '150px' }}>
                            Dead Weight (Tons)
                          </TheadCell>
                          <TheadCell style={{ width: '125px' }}>
                            Gross Tonnage
                          </TheadCell>
                          <TheadCell>Billing Time Arrival</TheadCell>
                          <TheadCell>Billing Time Departure</TheadCell>
                          <TheadCell style={{ width: '120px' }}>
                            Billing Duration
                          </TheadCell>
                        </TheadRow>
                      </Thead>
                      <Tbody>
                        <TheadRow style={{ height: '50px' }}>
                          <TbodyCell style={{ width: '170px' }}>
                            {dataPrint?.VesselName}
                          </TbodyCell>
                          <TbodyCell style={{ width: '105px' }}>
                            {dataPrint?.Jetty_Name_Index}
                          </TbodyCell>
                          <TbodyCell style={{ width: '175px' }}>
                            {dataPrint?.Length_Overall}
                          </TbodyCell>
                          <TbodyCell style={{ width: '150px' }}>
                            {dataPrint?.Dead_Weight}
                          </TbodyCell>
                          <TbodyCell style={{ width: '125px' }}>
                            {dataPrint?.Gross_Tonnage}
                          </TbodyCell>
                          <TbodyCell>
                            {ConvertLocalTimeGet(
                              dataSr?.Location,
                              dataPrint?.Billing_Time_Arrival,
                              'DD MMMM YYYY HH:mm'
                            )}
                          </TbodyCell>
                          <TbodyCell>
                            {ConvertLocalTimeGet(
                              dataSr?.Location,
                              dataPrint?.Billing_Time_Depart,
                              'DD MMMM YYYY HH:mm'
                            )}
                          </TbodyCell>
                          <TbodyCell
                            style={{ color: Colors.softBlue, width: '120px' }}
                          >
                            {`${dataPrint?.Billing_Berth_Dur_Detail} Hours`}
                          </TbodyCell>
                        </TheadRow>
                      </Tbody>
                    </Table>
                  )}
                </div>
                <div
                  style={{
                    margin: '0 auto',
                    width:
                      signForm?.values?.template_type === 'wet_sign'
                        ? '65%'
                        : '60%'
                  }}
                >
                  {signForm?.values?.template_type === 'wet_sign' ? (
                    <Table>
                      <thead>
                        <tr style={{ height: '50px' }}>
                          <TheadCell
                            className='left'
                            width={
                              signForm?.values?.template_type === 'wet_sign' &&
                              '200px'
                            }
                          ></TheadCell>
                          <TheadCell
                            className='left'
                            width={
                              signForm?.values?.template_type === 'wet_sign' &&
                              '250px'
                            }
                          >
                            Approver Name
                          </TheadCell>
                          <TheadCell className='left'>Signatories</TheadCell>
                          {signForm?.values?.template_type === 'wet_sign' && (
                            <TheadCell
                              className='left'
                              style={{ width: '150px' }}
                            >
                              Date
                            </TheadCell>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {approvalGrid(dataPrint?.Settlement_Approval || [])}
                      </tbody>
                    </Table>
                  ) : (
                    <Table>
                      <thead>
                        <tr style={{ height: '50px' }}>
                          <TheadCell
                            className='left'
                            width={
                              signForm?.values?.template_type === 'wet_sign' &&
                              '250px'
                            }
                          >
                            Approver Name
                          </TheadCell>
                          <TheadCell
                            className='left'
                            width={
                              signForm?.values?.template_type === 'wet_sign' &&
                              '200px'
                            }
                          ></TheadCell>
                          <TheadCell className='left'>Signatories</TheadCell>
                          {signForm?.values?.template_type === 'wet_sign' && (
                            <TheadCell
                              className='left'
                              style={{ width: '150px' }}
                            >
                              Date
                            </TheadCell>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {approvalGrid(dataPrint?.Settlement_Approval || [])}
                      </tbody>
                    </Table>
                  )}
                </div>
                {/* <div style={{ margin: '20% 30px 0px' }}>
                  <div style={{ fontSize: '16px' }}>
                    Print Out Date : {moment().format('DD MMMM YYYY')}
                  </div>
                </div> */}
              </div>
            </PDFExport>
          </div>
        </MasterModal>
      </MasterModal>
    </>
  )
}

export default SettlementModal
