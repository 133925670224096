import { useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Dialog } from '@progress/kendo-react-dialogs'
import CircularProgress from '@material-ui/core/CircularProgress'

import { InlineEditableGrid } from '~/components'

import SC from '../styled.activity'
import constants from '../constants.activity'

import gridColumns from './columns.json'
import Funcs from './functions.add-task.activity'

const AddEquipmentTaskModal = ({
  srCode,
  dataAll,
  inEditMode,
  handleClose,
  activityList,
  showModalStatus
}) => {
  const { Location: location } = dataAll
  const [isLoading, setIsLoading] = useState(true)
  const [inEditValues, setInEditValues] = useState(constants.initValTask)

  const tableWidth = Funcs.useCalculateTableWidth(gridColumns)
  const dropDownOptions = Funcs.useFetchDropDownOptions(
    srCode,
    [],
    dataAll.Location_Code
  )
  const [taskDetailList, setTaskDetailList] = Funcs.useFetchTaskDetailList(
    showModalStatus.activityCode,
    showModalStatus.taskCode,
    setIsLoading,
    location
  )

  const addTaskForm = Funcs.createForm(inEditValues, activityList, showModalStatus.activityCode, showModalStatus.taskCode)
  const handleRemove = Funcs.handleRemoveHOF(taskDetailList, setTaskDetailList)
  const handleEdit = Funcs.handleEditHOF(
    taskDetailList,
    setInEditValues,
    setTaskDetailList
  )
  const handleCancel = Funcs.handleCancelHOF(
    taskDetailList,
    setInEditValues,
    setTaskDetailList
  )
  const addNewTaskRow = Funcs.addNewTaskRowHOF(
    addTaskForm,
    taskDetailList,
    setInEditValues,
    setTaskDetailList
  )
  const handleSave = Funcs.handleSaveHOF(
    addTaskForm,
    dropDownOptions,
    showModalStatus,
    taskDetailList,
    setTaskDetailList,
    location,
  )

  return (
    <Dialog onClose={handleClose}>
      <SC.AddTaskModalContainer>
        <SC.AddTaskModalTop>
          <SC.AddTaskModalTopTitle>
            {showModalStatus.taskName}
          </SC.AddTaskModalTopTitle>
          <div id='close-modal'>
            <SC.CloseIcon onClick={handleClose} />
          </div>
        </SC.AddTaskModalTop>
        {isLoading ? (
          <SC.CircularProgressWrapper>
            <CircularProgress />
          </SC.CircularProgressWrapper>
        ) : (
          <>
            {inEditMode && (
              <SC.ButtonAddTaskContainer>
                <SC.AddItemWrapper onClick={addNewTaskRow}>
                  <AddIcon /> Add Item
                </SC.AddItemWrapper>
              </SC.ButtonAddTaskContainer>
            )}
            <SC.AddTaskGridContainer>
              <InlineEditableGrid
                sticky
                isFixedCell
                tableHeight='550px'
                data={taskDetailList}
                columns={gridColumns}
                tableWidth={tableWidth}
                handleEdit={handleEdit}
                handleSave={handleSave}
                formSubmit={addTaskForm}
                rowData={dropDownOptions}
                handleRemove={handleRemove}
                handleCancel={handleCancel}
                hideActionColumn={!inEditMode}
              />
            </SC.AddTaskGridContainer>
          </>
        )}
      </SC.AddTaskModalContainer>
    </Dialog>
  )
}

export default AddEquipmentTaskModal
