/* eslint-disable no-unused-vars */
import _, { isEmpty } from 'lodash'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useState, useEffect } from 'react'

import { ExecutionAPI } from '~/api'
import {
  ConvertLocalTimeGet,
  ConvertLocalTimePost,
  getDurationBetweenTwoTime
} from '~/utils'

import constants from '../constants.activity'

const calculateTableWidth = (columns) => {
  const calculatedColumns = columns
    .filter((column) => column.width != '*')
    .map((column) => {
      column.width.replace('px', '')
      return parseInt(column.width)
    })
  return calculatedColumns.reduce((column, index) => column + index, 0)
}

const useCalculateTableWidth = (columns) => {
  const width = window.innerWidth - (true ? 0 : 234)

  return calculateTableWidth(columns) > width
    ? calculateTableWidth(columns)
    : width
}

// This custom hook is used to fetch data unit and equipment labour data
const useFetchDropDownOptions = (srCode, initvalDropDown, locationCode) => {
  const [dropDownOptions, setDropDownOptions] = useState(initvalDropDown)

  const fetchData = async () => {
    try {
      const queryUnit = {
        filters: [
          {
            value: locationCode,
            field: 'location_code',
            type: 'text'
          }
        ]
      }
      const unitNameResponse = await ExecutionAPI.getAllMasterUnit(queryUnit)
      const equipLabourResponse = await ExecutionAPI.getEquipmentLabourBySRCode(
        srCode
      )

      setDropDownOptions({
        unit_name_code: unitNameResponse.data.message.data ?? [],
        estimation_detail_code: equipLabourResponse.data.message.data ?? []
      })
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    if (srCode) {
      fetchData()
    }
  }, [srCode])

  return dropDownOptions
}

const createForm = (inEditValues, activities, actCode, taskCode) => {
  const activity = activities?.find((act) => act.code === actCode)
  const task = activity?.progress_task_header_list?.find((task) => task?.code === taskCode)
  return useFormik({
    initialValues: inEditValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
     billing_start_time: Yup.date()
      .required('This field can\'t be empty')
      .test(
        'start-time-check-lower',
        'Must be >= task billing start time',
        function (value) {
          const { billng_start_time } = task

          if (new Date(value) < new Date(billng_start_time)) {
            return false
          }

          return true
        }
      )
      .test(
        'start-time-check-upper',
        'Must be <= task billing finish time',
        function (value) {
          const { billng_finish_time } = task

          if (new Date(value) > new Date(billng_finish_time)) {
            return false
          }

          return true
        }
      ),
      billing_finish_time: Yup.date()
      .required('This field can\'t be empty')
      .min(
        Yup.ref('billing_start_time'),
        'Must be greater than Billing Start Time'
      )
      .test(
        'finish-time-check-lower',
        'Must be >= task billing start time',
        function (value) {
          const { billng_finish_time } = task

          if (new Date(value) < new Date(billng_finish_time)) {
            return false
          }

          return true
        }
      )
      .test(
        'finish-time-check-upper',
        'Must be <= task billing finish time',
        function (value) {
          const { billng_finish_time } = task

          if (new Date(value) > new Date(billng_finish_time)) {
            return false
          }

          return true
        }
      ),
    }),
  })
}
const addNewTaskRowHOF = (
  addTaskForm,
  taskDetailList,
  setInEditValues,
  setTaskDetailList
) => {
  return () => {
    try {
      addTaskForm.resetForm()
      const newTask = { ...constants.initValTask, edit: true, isNew: true }
      const updatedData = [newTask, ...taskDetailList].map((item, index) => ({
        ...item,
        editIndex: index
      }))
      setInEditValues(newTask)
      setTaskDetailList(updatedData)
    } catch (error) {
      return error
    }
  }
}

const updateTask = async (task, location) => {
  try {
    const break_time = !isNaN(parseFloat(task.break_time))
      ? parseFloat(task.break_time)
      : 0
    task = {
      ...task,
      qty: parseFloat(task.qty),
      break_time: break_time,
      start_time: ConvertLocalTimePost(location, task.start_time),
      finish_time: ConvertLocalTimePost(location, task.finish_time),
      billing_start_time: ConvertLocalTimePost(
        location,
        task.billing_start_time
      ),
      billing_finish_time: ConvertLocalTimePost(
        location,
        task.billing_finish_time
      )
    }
    const response = await ExecutionAPI.updateProgressTaskDetail(task)
    return new Promise(function (resolve, reject) {
      resolve(response?.data?.message?.data ?? false)
      reject(new Error('Failed to update task detail'))
    })
  } catch (error) {
    return error
  }
}

const insertTask = async (task, location) => {
  try {
    const break_time = !isNaN(parseFloat(task.break_time))
      ? parseFloat(task.break_time)
      : 0
    task = {
      ...task,
      qty: parseFloat(task.qty),
      break_time: break_time,
      start_time: task.start_time
        ? ConvertLocalTimePost(location, task.start_time)
        : null,
      finish_time: task.finish_time
        ? ConvertLocalTimePost(location, task.finish_time)
        : null,
      billing_start_time: task.billing_start_time
        ? ConvertLocalTimePost(location, task.billing_start_time)
        : null,
      billing_finish_time: task.billing_finish_time
        ? ConvertLocalTimePost(location, task.billing_finish_time)
        : null
    }

    const response = await ExecutionAPI.insertProgressTaskDetail(task)
    return new Promise(function (resolve, reject) {
      resolve(response?.data?.message?.data ?? false)
      reject(new Error('Failed to insert task detail'))
    })
  } catch (error) {
    return error
  }
}

const fetchTaskDetail = async (activityCode, taskHeaderCode, location) => {
  try {
    const response = await ExecutionAPI.getProgressTaskDetailByTaskHeaderCode(
      activityCode,
      taskHeaderCode
    )
    const newResponse = (response?.data?.message?.data ?? []).map((e) => {
      return {
        ...e,
        start_time: ConvertLocalTimeGet(
          location,
          e.start_time,
          'YYYY-MM-DD HH:mm'
        ),
        finish_time: ConvertLocalTimeGet(
          location,
          e.finish_time,
          'YYYY-MM-DD HH:mm'
        ),
        billing_start_time: ConvertLocalTimeGet(
          location,
          e.billing_start_time,
          'YYYY-MM-DD HH:mm'
        ),
        billing_finish_time: ConvertLocalTimeGet(
          location,
          e.billing_finish_time,
          'YYYY-MM-DD HH:mm'
        )
      }
    })
    return new Promise(function (resolve, reject) {
      resolve(newResponse)
      reject(new Error('Failed to fetch task detail'))
    })
  } catch (error) {
    return error
  }
}

const useFetchTaskDetailList = (activityCode, taskHeaderCode, setIsLoading, location) => {
  const [taskDetailList, setTaskDetailList] = useState([])

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const result = await fetchTaskDetail(activityCode, taskHeaderCode, location)
      setTaskDetailList(result)
      setIsLoading(false)
    } catch(error) {
      return error
    }
  }

  useEffect(() => {
    if (taskHeaderCode) {
      fetchData()
    }
  }, [taskHeaderCode])

  return [taskDetailList, setTaskDetailList]
}

const handleSaveHOF = (
  addTaskForm,
  dropDownOptions,
  showModalStatus,
  taskDetailList,
  setTaskDetailList,
  location,
) => {
  return async (item) => {
    let unit_desc = dropDownOptions.unit_name_code.filter(
      (e) => e.code === addTaskForm.values.unit_name_code
    )
    const errors = addTaskForm?.errors
    if (!isEmpty(errors)) {
      return
    }
    try {
      let newOrUpdatedTask
      taskDetailList.forEach((val, index) => {
        if (item.editIndex === val.editIndex) {
          const findEstimationHeader =
            dropDownOptions.estimation_detail_code.find(
              (est) =>
                est.estimation_detail_code ===
                addTaskForm.values.estimation_detail_code
            )
          newOrUpdatedTask = {
            ...addTaskForm.values,
            editIndex: index,
            activity_code: showModalStatus.activityCode,
            task_header_code: showModalStatus.taskCode,
            unit_name_description:
              (unit_desc || []).length !== 0 ? unit_desc[0].description : '',
            duration_time: getDurationBetweenTwoTime(
              addTaskForm.values.start_time,
              addTaskForm.values.finish_time,
              addTaskForm.values.break_time
            ),
            billing_dur_time: getDurationBetweenTwoTime(
              addTaskForm.values.billing_start_time,
              addTaskForm.values.billing_finish_time,
              addTaskForm.values.break_time
            ),
            estimation_header_code: !_.isUndefined(findEstimationHeader)
              ? findEstimationHeader.estimation_header_code
              : ''
          }
          return newOrUpdatedTask
        }
        return { ...val, editIndex: index }
      })
      if (item.isNew) {
        await insertTask(
          { ...newOrUpdatedTask, edit: false, isNew: false },
          location
        )
      } else {
        await updateTask({ ...newOrUpdatedTask, edit: false }, location)
      }

      const newlyFetchedTaskDetailList = await fetchTaskDetail(
        showModalStatus.activityCode,
        showModalStatus.taskCode,
        location
      )
      setTaskDetailList(newlyFetchedTaskDetailList)
    } catch (error) {
      return error
    }
  }
}

const handleCancelHOF = (
  taskDetailList,
  setInEditValues,
  setTaskDetailList
) => {
  return (item) => {
    const newInitVals = { ...constants.initValCargo, edit: false, isNew: false }
    const updatedData = taskDetailList.map((val, index) => {
      if (item.editIndex === val.editIndex)
        return { ...val, edit: false, editIndex: index }
      return { ...val, editIndex: index }
    })

    updatedData.filter((e, index) => e.isNew && updatedData.splice(index, 1))
    setInEditValues(newInitVals)
    setTaskDetailList(updatedData)
  }
}

const handleEditHOF = (taskDetailList, setInEditValues, setTaskDetailList) => {
  return (item) => {
    const wantTobeUpdatedItem = { ...item, edit: true, isNew: false }
    const updatedData = taskDetailList.map((val, index) => {
      if (item.id === val.id) return { ...val, edit: true, editIndex: index }
      return { ...val, editIndex: index }
    })
    setInEditValues(wantTobeUpdatedItem)
    setTaskDetailList(updatedData)
  }
}

const deleteTask = async (taskId) => {
  try {
    const response = await ExecutionAPI.deleteProgressTaskDetail(taskId)
    return response
  } catch (error) {
    return error
  }
}

const handleRemoveHOF = (taskDetailList, setTaskDetailList) => {
  return (item) => {
    const filteredData = taskDetailList.filter((val) => val.id !== item.id)

    deleteTask(item.id)
    setTaskDetailList(filteredData)
  }
}

export default {
  createForm,
  handleSaveHOF,
  handleEditHOF,
  handleCancelHOF,
  handleRemoveHOF,
  addNewTaskRowHOF,
  useFetchTaskDetailList,
  useCalculateTableWidth,
  useFetchDropDownOptions
}
