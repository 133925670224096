import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Add, Remove, MyLocation, HelpOutlineOutlined } from '@material-ui/icons'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'

import { Images } from '../../assets'
import { UPDATE_VIEW_MAP } from '../../redux/actions'
import { Maps, Satellite, StreetView, FullScreenEnter, FullScreenExit, VesselIcon } from '../../components'
import { DashboardAPI } from '../../api'
import {
  Zoom,
  LegendText,
  IconWrapper,
  ZoomWrapper,
  FlexWrapper,
  LegendWrapper,
  FlexOuterWrapper,
  LegendOuterWrapper,
  ControlPanelWrapper,
} from './dashboard-map.styled'

const DashboardMap = () => {
  const dispatch = useDispatch()
  const handle = useFullScreenHandle()
  const viewPort = useSelector((state) => state.mapsReducer)

  const [isRecenter, setIsRecenter] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isLegendShowing, setIsLegendShowing] = useState(false)
  const [isSatelliteView, setIsSatelliteView] = useState(false)
  const [location, setLocation] = useState({ lat: -1.190186, lng: 116.790456, error: 'default' })
  const [userLocation, setUserLocation] = useState({ lat: -1.190186, lng: 116.790456, error: 'default' })
  const [listPort, setListPort] = useState([])
  const [listVessel, setListVessel] = useState([])
  const [intervalVessel, setIntervalVessel] = useState(15)
  const [vessel, setVessel] = useState([])

  useEffect(() => {
    const loadLocation = async () => {
      await _getLocation()
    }

    loadLocation()

    document.addEventListener('fullscreenchange', _handleEsc)
    return () => {
      document.removeEventListener('fullscreenchange', _handleEsc)
    }
  }, [listPort])

  useEffect(() => {
    _getIntervalVessel()
    _getListPort()
    _getListVessel()
  }, [])

  useEffect(() => {
    const intervalId = setInterval(()=>{
      _getListVessel()
    }, intervalVessel * 60 * 1000)
    return () => clearInterval(intervalId)
  }, [intervalVessel])

  const _getIntervalVessel = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"Interval_Vessel"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await DashboardAPI.GetIntervalVessel(0, 100, filter)
      if(data && data[0] && data[0].Value) {
        setIntervalVessel(parseInt(data[0].Value))
      }
    } catch (err) {
      console.log('error', err)
    }
  }

  const _getListPort = async () => {
    try {
      const { data } = await DashboardAPI.GetAllPort()
      if(data) {
        setListPort(data)
      }
    } catch (err) {
      console.log('error', err)
    }
  }

  const _getListVessel = async () => {
    try {
      const { data } = await DashboardAPI.GetAllVessel()
      if(data) {
        setListVessel(data)

        const vesselDropdown = [...new Set(data
          .filter(obj => obj.Vessel_Name && obj.Vessel_Code && Number(obj.Latitude) && Number(obj.Longitude))
          .map(obj => obj.Vessel_Name))]
          .map(Vessel_Name => data
          .find(obj => obj.Vessel_Name === Vessel_Name && obj.Vessel_Name))
        setVessel(vesselDropdown)
      }
    } catch (err) {
      console.log('error', err)
    }
  }

  const _changeHandleScreen = () => {
    if (isFullScreen) {
      setIsFullScreen(false)
      return handle.exit()
    } else {
      setIsFullScreen(true)
      return handle.enter()
    }
  }

  const _handleEsc = () => {
    if (window.innerHeight === screen.height) {
      setIsFullScreen(true)
    } else {
      setIsFullScreen(false)
    }
  }

  const _changeMapType = () => {
    if (isSatelliteView) {
      setIsSatelliteView(false)
    } else {
      setIsSatelliteView(true)
    }
  }

  const _getLocation = async () => {
    await navigator.geolocation.getCurrentPosition(
      function (position) {
        const { latitude, longitude } = position.coords
        setUserLocation({ ...location, lat: latitude, lng: longitude, error: '' })
        setLocation({ ...location })
      },
      function (error) {
        console.error('Error Code = ' + error.code + ' - ' + error.message)
      },
      { timeout: 10000 }
    )
  }

  const _setBackToDefaultLocation = () => {
    setIsRecenter(true)
  }

  return (
    <FullScreen handle={handle}>
      <div style={{ position: 'relative' }}>
        <Maps
          vesselDropdown={vessel}
          listVessel={listVessel}
          listPort={listPort}
          location={location}
          isRecenter={isRecenter}
          isFullScreen={isFullScreen}
          userLocation={userLocation}
          setIsRecenter={setIsRecenter}
          isSatelliteView={isSatelliteView}
        />
        <ControlPanelWrapper>
          <FlexOuterWrapper>
            <FlexWrapper>
              <IconWrapper onClick={_changeHandleScreen} id='dsb-cpanel-fullscreen-btn'>
                {isFullScreen ? <FullScreenExit width='1.94vh' height='3.07vh'/> : <FullScreenEnter width='1.94vh' height='3.07vh'/>}
              </IconWrapper>
              <IconWrapper onClick={_changeMapType} id='dsb-cpanel-satellite-btn'>
                {isSatelliteView ? <StreetView width='2.5vh' height='2.5vh'/> : <Satellite width='2.04vh' height='3.07vh'/>}
              </IconWrapper>
              <IconWrapper onClick={_setBackToDefaultLocation} lineHeight='55px' id='dsb-cpanel-location-btn'>
                <MyLocation viewBox='0 10 24 3'/>
              </IconWrapper>
              <IconWrapper onClick={() => setIsLegendShowing(!isLegendShowing)} lineHeight='55px' id='dsb-cpanel-legend-btn'>
                <HelpOutlineOutlined style={{ fontSize:'2.5vh' }} viewBox='0 10 24 3'/>
              </IconWrapper>
              <ZoomWrapper>
                <Zoom
                  firstChild={1}
                  onClick={() => {
                    dispatch({
                      type: UPDATE_VIEW_MAP,
                      payload: {
                        ...viewPort,
                        zoom: viewPort.zoom + 1,
                      },
                    })
                  }}
                >
                  <Add />
                </Zoom>
                <Zoom
                  onClick={() => {
                    dispatch({
                      type: UPDATE_VIEW_MAP,
                      payload: {
                        ...viewPort,
                        zoom: viewPort.zoom - 1,
                      },
                    })
                  }}
                >
                  <Remove />
                </Zoom>
              </ZoomWrapper>
            </FlexWrapper>
            {isLegendShowing && (
              <LegendOuterWrapper>
                <LegendWrapper>
                  <LegendText>
                    <img src={Images.portIcon} style={{ marginLeft: 3, width: '1.5em' }}/>
                    <span style={{ marginLeft: 7 }}>
                      Port
                    </span>
                  </LegendText>
                  <LegendText>
                    <VesselIcon dataIndex='0' fill='black' />
                    <span style={{ marginLeft: 5 }}>
                      Regular Vessel (without SR)
                    </span>
                  </LegendText>
                  <LegendText>
                    <VesselIcon dataIndex='2' />
                    <span style={{ marginLeft: 5 }}>
                      Vessel with SR (with Contract)
                    </span>
                  </LegendText>
                </LegendWrapper>
              </LegendOuterWrapper>
            )}
          </FlexOuterWrapper>
        </ControlPanelWrapper>
      </div>
    </FullScreen>
  )
}

export default DashboardMap
